import { useLocation, useNavigate } from "@remix-run/react";

type CustomNavigateFunction = (
    to: string,
    options?: {
        replace?: boolean;
        state?: any;
    }
) => void;

export function useCustomNavigate(): CustomNavigateFunction {
    const navigate = useNavigate();
    const location = useLocation();

    const enhancedNavigate: CustomNavigateFunction = (to, options = {}) => {
        const url = new URL(to, window.location.origin);
        const fromValue = encodeURIComponent(`${location.pathname}${location.search}`);
        url.searchParams.append('from', fromValue);

        navigate(url.pathname + url.search, options);
    }

    return enhancedNavigate
}